import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import mediaqueries from "@styles/media";

function Introduction() {
  return (
    <Layout>
      <SEO />
      <Section>
        <Width>
          <Headings.h1 style={{ margin: "100px 0 50px" }}>Welcome!</Headings.h1>
          <Intro>
            <div>
              <p>
                Thank you for coming to my blog
                <span role="img" aria-label="smile">
                  🙂
                </span>
              </p>
              <p>I'm Keiko, in Japan, Tokyo.</p>
              <p>
                I have a graphic &amp; web designing background and have been
                learning JavaScript and English.
              </p>
              <p>Currently creating a simple study app by React.</p>
              <p>
                Introvert and health-conscious person. Sometimes get unstable so
                trying to make it stronger
                <span role="img" aria-label="muscle">
                  💪
                </span>
              </p>
              <p>
                <a href="https://justdancenow.com/">Just Dance Now</a> is my
                latest good small hobby
                <span role="img" aria-label="dance">
                  💃🏻
                </span>
              </p>
              <p>Also, sleeping is essential!</p>
              <h2 id="taskstodos">Tasks Todos</h2>
              <p>There are a lot of tasks that want to make it happen.</p>
              <ul>
                <li>Study app (underway)</li>
                <li>JavaScript playground (underway)</li>
                <li>Renewal portfolio site</li>
                <li>Bookmark app</li>
                <li>Video chat app</li>
              </ul>
              <p>and so on...</p>
              <h2 id="tentativeschedule">Tentative schedule</h2>
              <h3 id="september2020">October 2020</h3>
              <p>The study app (MVP)</p>
              <h3 id="december2020">December 2020</h3>
              <p>Might get a job or...?</p>
              <hr />
              <p>This information will be updated...</p>
            </div>
          </Intro>
        </Width>
      </Section>
    </Layout>
  );
}

export default Introduction;

const commonStyles = (p) => css`
  font-weight: bold;
  color: ${p.theme.colors.primary};
  font-family: ${p.theme.fonts.serif};
`;

const Width = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  ${mediaqueries.desktop`
    max-width: 507px;
  `}
  ${mediaqueries.tablet`
    max-width: 486px;
  `};
`;

const ARTICLE_WIDTH = css`
  ${mediaqueries.phablet`
  padding: 0 20px;
  `};
`;

const Intro = styled.div`
  ul {
    text-indent: 2rem;
    margin: 0 auto 2rem;
  }

  li {
    margin-bottom: 1rem;
  }

  hr {
    display: block;
    margin: 8rem auto;
  }

  p {
    line-height: 1.756;
    font-size: 18px;
    color: ${(p) => p.theme.colors.articleText};
    font-family: ${(p) => p.theme.fonts.sansSerif};
    transition: ${(p) => p.theme.colorModeTransition};
    margin: 0 auto 20px;
    width: 100%;
    max-width: 680px;
    b {
      font-weight: 800;
    }
    ${mediaqueries.desktop`
    max-width: 507px;
  `}
    ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};
    ${mediaqueries.phablet`
    padding: 0 20px;
  `};
    a {
      transition: ${(p) => p.theme.colorModeTransition};
      color: ${(p) => p.theme.colors.accent};

      &:visited {
        color: ${(p) => p.theme.colors.accent};
        opacity: 0.85;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  h2 {
    margin: 7rem auto 18px;
    word-break: keep-all;
    font-size: 32px;
    line-height: 1.333;
    ${commonStyles};
    ${ARTICLE_WIDTH};

    ${mediaqueries.desktop`
    font-size: 24px;
  `};

    ${mediaqueries.tablet`
    font-size: 24px;
    line-height: 1.45;
  `};

    ${mediaqueries.phablet`
    font-size: 22px;
  `};
  }

  h3 {
    margin: 20px auto 10px;
    word-break: keep-all;
    font-size: 24px;
    line-height: 1.45;
    ${commonStyles};
    ${ARTICLE_WIDTH};

    ${mediaqueries.desktop`
    font-size: 22px;
  `};

    ${mediaqueries.tablet`
    font-size: 22px;
  `};

    ${mediaqueries.phablet`
    font-size: 20px;
  `}
  }

  h4 {
    margin: 2rem 0 1rem;
    word-break: keep-all;
    font-size: 18px;
    line-height: 1.45;
    ${commonStyles};
    ${ARTICLE_WIDTH};

    ${mediaqueries.phablet`
    font-size: 16px;
  `};
  }

  h5 {
    margin: 2rem 0 1rem;
    word-break: keep-all;
    font-size: 18px;
    line-height: 1.45;
    ${commonStyles};
    ${ARTICLE_WIDTH};

    ${mediaqueries.phablet`
    font-size: 16px;
  `};
  }

  h6 {
    word-break: keep-all;
    font-size: 16px;
    line-height: 1.45;
    ${commonStyles};
    ${ARTICLE_WIDTH};

    ${mediaqueries.phablet`
      font-size: 14px;
  `};
  }
`;
